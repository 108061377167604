import React from 'react'
import './all.sass'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Image = function ({ filepath, caption }) {
  const data = useStaticQuery(graphql`
    {
      images: allFile(filter: { relativePath: { regex: "/.png/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)
  let image = data.images.edges[0].node
  for (let i = 0; i < data.images.edges.length; i++) {
    if (data.images.edges[i].node.relativePath === filepath) {
      image = data.images.edges[i].node
    }
  }
  return (
    <figure>
      <GatsbyImage
        image={getImage(image)}
        placeholder="blurred"
        alt={`${filepath}`}
        width={400}
      />
      <figcaption>{caption}</figcaption>
    </figure>
  )
}

export default Image
