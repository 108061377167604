import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'
import Checkbox from '../components/Checkbox.component'

export default function formativeEvaluationTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <div className="content">
      <Layout title={documentName} filePath={filePath}>
        <h2 className="title is-2 has-text-info-dark">
          {frontmatter.productName} Formative Evaluation
        </h2>
        <h3 className="title is-3 has-text-info-dark">
          Design of the Product (Appearance)
        </h3>
        Date of Evaluation: {frontmatter.dateOfEvaluation}
        <Table table={frontmatter.productDesignTable} />
        <p className="ml-5">
          Aesthetic: {frontmatter.aesthetic} <br />
          Material: {frontmatter.material}
          <br />
          Components: {frontmatter.components}
          <br />
          Manufacturing & Finish: {frontmatter.manufacturingAndComponents}
          <br />
          Color: {frontmatter.color}
          <br />
          Other: {frontmatter.other}
        </p>
        <b>Conclusion: {frontmatter.designOfTheProductConclusionConclusion}</b>
        <br />
        <br />
        <b>
          Notes for Design Changes and Next Steps:{' '}
          {frontmatter.designOfTheProductNotes}
        </b>
        <p className="ml-6">
          <br />
          <Checkbox
            checked={
              frontmatter.designOfTheProductConclusionComponentApprovedCheckbox ===
              'true'
            }
          />
          &nbsp;Component Approved
          <br />
          <Checkbox
            checked={
              frontmatter.designOfTheProductConclusionModifyComponentCheckbox ===
              'true'
            }
          />
          &nbsp;Modify Component:{' '}
          {frontmatter.designOfTheProductConclusionModifyComponentExplanation}
          <br />
          <Checkbox
            checked={
              frontmatter.designOfTheProductConclusionOtherCheckbox === 'true'
            }
          />
          &nbsp;Other:{' '}
          {frontmatter.designOfTheProductConclusionOtherExplanation}
        </p>
        <h3 className="title is-3 has-text-info-dark">
          Verification (Functionality)
        </h3>
        Date of Test: {frontmatter.verificationDateOfTest}
        <br />
        Verification Procedure: {frontmatter.verificationProcedure}
        <Table table={frontmatter.verificationTable} />
        <Table table={frontmatter.verificationTestTable} />
        <b>Conclusion: {frontmatter.verificationConclusion}</b>
        <br />
        <br />
        <b>
          Notes for Design Changes and Next Steps:{' '}
          {frontmatter.verificationNotes}
        </b>
        <p className="ml-6">
          <br />
          <Checkbox
            checked={
              frontmatter.verificationComponentApprovedCheckbox === 'true'
            }
          />
          &nbsp;Component Approved
          <br />
          <Checkbox
            checked={frontmatter.verificationModifyComponentCheckbox === 'true'}
          />
          &nbsp;Modify Component:{' '}
          {frontmatter.verificationModifyComponentExplanation}
          <br />
          <Checkbox
            checked={
              frontmatter.verificationUpdateRequirementCheckbox === 'true'
            }
          />
          &nbsp;Update Requirement:{' '}
          {frontmatter.verificationUpdateRequirementExplanation}
          <br />
          <Checkbox
            checked={frontmatter.verificationOtherCheckbox === 'true'}
          />
          &nbsp;Other: {frontmatter.verificationOtherExplanation}
        </p>
        <h3 className="title is-3 has-text-info-dark">
          Validation (Functionality)
        </h3>
        Date of Test: {frontmatter.validationDateOfTest}
        <br />
        Validation Procedure: {frontmatter.validationProcedure}
        <Table table={frontmatter.validationTable} />
        <Table table={frontmatter.validationTestTable} />
        <b>Conclusion: {frontmatter.validationConclusion}</b>
        <br />
        <br />
        <b>
          Notes for Design Changes and Next Steps: {frontmatter.validationNotes}
        </b>
        <p className="ml-6">
          <br />
          <Checkbox
            checked={frontmatter.validationComponentApprovedCheckbox === 'true'}
          />
          &nbsp;Component Approved
          <br />
          <Checkbox
            checked={frontmatter.validationModifyComponentCheckbox === 'true'}
          />
          &nbsp;Modify Component:{' '}
          {frontmatter.validationModifyComponentExplanation}
          <br />
          <Checkbox
            checked={
              frontmatter.validationUpdateTestProcedureCheckbox === 'true'
            }
          />
          &nbsp;Update Test Procedure:{' '}
          {frontmatter.validationUpdateTestProcedureExplanation}
          <br />
          <Checkbox checked={frontmatter.validationOtherCheckbox === 'true'} />
          &nbsp;Other: {frontmatter.validationOtherExplanation}
        </p>
        <h3 className="title is-3 has-text-info-dark">
          Manufacturing Processes
        </h3>
        Date of Evaluation or Test:{' '}
        {frontmatter.manufacturingProcessesDateOfEvaluationOrTest}
        <Table table={frontmatter.manufacturingProcessesTable} />
        <b>Process Related -</b>
        <p className="ml-6">
          <br />
          <Checkbox
            checked={
              frontmatter.manufacturingProcessesConfusingProcedureCheckbox ===
              'true'
            }
          />
          &nbsp;Confusing Procedure:{' '}
          {frontmatter.manufacturingProcessesConfusingProcedureExplanation}
          <br />
          <Checkbox
            checked={
              frontmatter.manufacturingProcessesTimeConsumingCheckbox === 'true'
            }
          />
          &nbsp;Time Consuming:{' '}
          {frontmatter.manufacturingProcessesTimeConsumingExplanation}
          <br />
          <Checkbox
            checked={
              frontmatter.manufacturingProcessesDifficultTaskCheckbox === 'true'
            }
          />
          &nbsp;Difficult Task:{' '}
          {frontmatter.manufacturingProcessesDifficultTaskExplanation}
        </p>
        <b>
          Notes for Design Changes and Next Steps:{' '}
          {frontmatter.manufacturingProcessesDesignChangeNotes}
        </b>
        <p className="ml-6">
          <br />
          <Checkbox
            checked={
              frontmatter.manufacturingProcessesComponentApprovedCheckbox ===
              'true'
            }
          />
          &nbsp; Component Approved
          <br />
          <Checkbox
            checked={
              frontmatter.manufacturingProcessesModifyComponentCheckbox ===
              'true'
            }
          />
          &nbsp;Modify Component:{' '}
          {frontmatter.manufacturingProcessesModifyComponentExplanation}
          <br />
          <Checkbox
            checked={
              frontmatter.manufacturingProcessesUpdateProcedureOrProcessCheckbox ===
              'true'
            }
          />
          &nbsp;Update Procedure/Process:{' '}
          {
            frontmatter.manufacturingProcessesUpdateProcedureOrProcessExplanation
          }
          <br />
          <Checkbox
            checked={
              frontmatter.manufacturingProcessesCreateAFixtureCheckbox ===
              'true'
            }
          />
          &nbsp;Create a Fixture:{' '}
          {frontmatter.manufacturingProcessesCreateAFixtureExplanation}
          <br />
          <Checkbox
            checked={frontmatter.manufacturingProcessesOtherCheckbox === 'true'}
          />
          &nbsp;Other: {frontmatter.manufacturingProcessesOtherExplanation}
        </p>
        <h3 className="title is-3 has-text-info-dark">
          User Interaction (Usability)
        </h3>
        <Table table={frontmatter.userInteractionTable} />
        <br />
        <h4 className="title is-4 has-text-info-dark">
          Other Materials Required for Evaluation
        </h4>
        <ul>
          <li>{frontmatter.userInteractionRequiredMaterials}</li>
        </ul>
        <ol type="1">
          <li>
            <h4 className="title is-4 has-text-info-dark">
              Usability Scenario:
            </h4>{' '}
            {frontmatter.userInteractionUsabilityScenarioOneDescription}
            <ol type="a">
              <li>
                <h4 className="title is-4 has-text-info-dark">Results</h4>
                <b>Date of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsOneDateOfEvaluation
                }
                <br />
                <b>Usability Procedure:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsOneUsabilityProcedure
                }
                <br />
                <b>Participant's Title:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsOneParticipantsTitle
                }
                <br />
                <b>Location of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsOneLocationOfEvaluation
                }
                <br />
                <b>Room Environment of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsOneRoomEnvironmentOfEvaluation
                }
                <table>
                  <tr>
                    <td>
                      {
                        frontmatter.userInteractionUsabilityScenarioOneResultsOneSectionOne
                      }
                    </td>
                    <td>
                      <Checkbox
                        checked={
                          frontmatter.userInteractionUsabilityScenarioOneResultsOneSectionOneCheckboxYes ===
                          'true'
                        }
                      />
                      &nbsp;Yes
                    </td>
                    <td>
                      <Checkbox
                        checked={
                          frontmatter.userInteractionUsabilityScenarioOneResultsOneSectionOneCheckboxNo ===
                          'true'
                        }
                      />
                      &nbsp;No
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {
                        frontmatter.userInteractionUsabilityScenarioOneResultsOneSectionTwo
                      }
                    </td>
                    <td>
                      <Checkbox
                        checked={
                          frontmatter.userInteractionUsabilityScenarioOneResultsOneSectionTwoCheckboxYes ===
                          'true'
                        }
                      />
                      &nbsp;Yes
                    </td>
                    <td>
                      <Checkbox
                        checked={
                          frontmatter.userInteractionUsabilityScenarioOneResultsOneSectionTwoCheckboxNo ===
                          'true'
                        }
                      />
                      &nbsp;No
                    </td>
                  </tr>
                </table>
                <b>Notes on How the Participant Used the Device:</b>
                <ul>
                  <li>
                    {
                      frontmatter.userInteractionUsabilityScenarioOneResultsOneParticipantNotes
                    }
                  </li>
                </ul>
              </li>
              <li>
                <h4 className="title is-4 has-text-info-dark">Results</h4>
                <b>Date of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsTwoDateOfEvaluation
                }
                <br />
                <b>Usability Procedure:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsTwoUsabilityProcedure
                }
                <br />
                <b>Participant's Title:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsTwoParticipantsTitle
                }
                <br />
                <b>Location of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsTwoLocationOfEvaluation
                }
                <br />
                <b>Room Environment of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsTwoRoomEnvironmentOfEvaluation
                }
              </li>
            </ol>
            <table>
              <tr>
                <td>
                  {
                    frontmatter.userInteractionUsabilityScenarioOneResultsTwoSectionOne
                  }
                </td>
                <td>
                  <Checkbox
                    checked={
                      frontmatter.userInteractionUsabilityScenarioOneResultsTwoSectionOneCheckboxYes ===
                      'true'
                    }
                  />
                  &nbsp;Yes
                </td>
                <td>
                  <Checkbox
                    checked={
                      frontmatter.userInteractionUsabilityScenarioOneResultsTwoSectionOneCheckboxNo ===
                      'true'
                    }
                  />
                  &nbsp;No
                </td>
              </tr>
              <tr>
                <td>
                  {
                    frontmatter.userInteractionUsabilityScenarioOneResultsTwoSectionTwo
                  }
                </td>
                <td>
                  <Checkbox
                    checked={
                      frontmatter.userInteractionUsabilityScenarioOneResultsTwoSectionTwoCheckboxYes ===
                      'true'
                    }
                  />
                  &nbsp;Yes
                </td>
                <td>
                  <Checkbox
                    checked={
                      frontmatter.userInteractionUsabilityScenarioOneResultsTwoSectionTwoCheckboxNo ===
                      'true'
                    }
                  />
                  &nbsp;No
                </td>
              </tr>
            </table>
            <b>Notes on How the Participant Used the Device:</b>
            <ul>
              <li>
                {
                  frontmatter.userInteractionUsabilityScenarioOneResultsTwoParticipantNotes
                }
              </li>
            </ul>
          </li>
          <br />
          <li>
            <h4 className="title is-4 has-text-info-dark">
              Usability Scenario:
            </h4>
            {frontmatter.userInteractionUsabilityScenarioTwoDescription}
            <ol type="a">
              <li>
                <h4 className="title is-4 has-text-info-dark">Results</h4>
                <b>Date of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioTwoResultsOneDateOfEvaluation
                }
                <br />
                <b>Usability Procedure:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioTwoResultsOneUsabilityProcedure
                }
                <br />
                <b>Participant's Title:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioTwoResultsOneParticipantsTitle
                }
                <br />
                <b>Location of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioTwoResultsOneLocationOfEvaluation
                }
                <br />
                <b>Room Environment of Evaluation:</b>{' '}
                {
                  frontmatter.userInteractionUsabilityScenarioTwoResultsOneRoomEnvironmentOfEvaluation
                }
                <table>
                  <tr>
                    <td>
                      {
                        frontmatter.userInteractionUsabilityScenarioTwoResultsOneSectionOne
                      }
                    </td>
                    <td>
                      <Checkbox
                        checked={
                          frontmatter.userInteractionUsabilityScenarioTwoResultsOneSectionOneCheckboxYes ===
                          'true'
                        }
                      />
                      &nbsp;Yes
                    </td>
                    <td>
                      <Checkbox
                        checked={
                          frontmatter.userInteractionUsabilityScenarioTwoResultsOneSectionOneCheckboxNo ===
                          'true'
                        }
                      />
                      &nbsp;No
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {
                        frontmatter.userInteractionUsabilityScenarioTwoResultsOneSectionTwo
                      }
                    </td>
                    <td>
                      <Checkbox
                        checked={
                          frontmatter.userInteractionUsabilityScenarioTwoResultsOneSectionTwoCheckboxYes ===
                          'true'
                        }
                      />
                      &nbsp;Yes
                    </td>
                    <td>
                      <Checkbox
                        checked={
                          frontmatter.userInteractionUsabilityScenarioTwoResultsOneSectionTwoCheckboxNo ===
                          'true'
                        }
                      />
                      &nbsp;No
                    </td>
                  </tr>
                </table>
                <b>Notes on How the Participant Used the Device:</b>
                <ul>
                  <li>
                    {
                      frontmatter.userInteractionUsabilityScenarioTwoResultsOneParticipantNotes
                    }
                  </li>
                </ul>
                <b>Notes for Design Changes and Next Steps:</b>
                <p className="ml-6">
                  <br />
                  <Checkbox
                    checked={
                      frontmatter.userInteractionUsabilityScenarioTwoResultsOneComponentApprovedCheckbox ===
                      'true'
                    }
                  />
                  &nbsp;Component Approved
                  <br />
                  <Checkbox
                    checked={
                      frontmatter.userInteractionUsabilityScenarioTwoResultsOneModifyComponentCheckbox ===
                      'true'
                    }
                  />
                  &nbsp;Modify Component:{' '}
                  {
                    frontmatter.userInteractionUsabilityScenarioTwoResultsOneModifyComponentExplanation
                  }
                  <br />
                  <br />
                  <Checkbox
                    checked={
                      frontmatter.userInteractionUsabilityScenarioTwoResultsOneIFUApprovedCheckbox ===
                      'true'
                    }
                  />
                  &nbsp;IFU Approved As-IS (Pre-marketing request)
                  <br />
                  <Checkbox
                    checked={
                      frontmatter.userInteractionUsabilityScenarioTwoResultsOneModifyContentCheckbox ===
                      'true'
                    }
                  />
                  &nbsp;Modify content on IFU:{' '}
                  {
                    frontmatter.userInteractionUsabilityScenarioTwoResultsOneModifyContentExplanation
                  }
                </p>
              </li>
            </ol>
          </li>
          <br />
          <li>
            <h4 className="title is-4 has-text-info-dark">Packaging</h4>
            <b>Date of Evaluation or Test:</b>{' '}
            {frontmatter.packagingDateOfEvaluationOrTest}
            <Table table={frontmatter.packagingTable} />
            <Table table={frontmatter.packagingTestTable} />
            <b>Packaging Related -</b>
            <p className="ml-6">
              <Checkbox
                checked={
                  frontmatter.packagingPackagingRelatedInformationCheckbox ===
                  'true'
                }
              />
              &nbsp;Information:{' '}
              {frontmatter.packagingPackagingRelatedInformationExplanation}
              <br />
              <Checkbox
                checked={
                  frontmatter.packagingPackagingRelatedOrientationCheckbox ===
                  'true'
                }
              />
              &nbsp;Orientation:{' '}
              {frontmatter.packagingPackagingRelatedOrientationExplanation}
              <br />
              <Checkbox
                checked={
                  frontmatter.packagingPackagingRelatedUserStorageCheckbox ===
                  'true'
                }
              />
              &nbsp;User Storage:{' '}
              {frontmatter.packagingPackagingRelatedUserStorageExplanation}
            </p>
            <b>Product Related -</b>
            <p className="ml-6">
              <Checkbox
                checked={
                  frontmatter.packagingProductRelatedVisualCheckbox === 'true'
                }
              />
              &nbsp;Visual:{' '}
              {frontmatter.packagingProductRelatedVisualExplanation}
              <br />
              <Checkbox
                checked={
                  frontmatter.packagingProductRelatedOrientationCheckbox ===
                  'true'
                }
              />
              &nbsp;Orientation:{' '}
              {frontmatter.packagingProductRelatedOrientationExplanation}
            </p>
            <b>Notes for Design Changes and Next Steps</b>
            <p className="ml-6">
              <Checkbox
                checked={
                  frontmatter.packagingNotesForDesignChangesAndNextStepsComponentApprovedCheckbox ===
                  'true'
                }
              />
              &nbsp;Component Approved
              <br />
              <Checkbox
                checked={
                  frontmatter.packagingNotesForDesignChangesAndNextStepsModifyPackagingCheckbox ===
                  'true'
                }
              />
              &nbsp;Modify Packaging:{' '}
              {
                frontmatter.packagingNotesForDesignChangesAndNextStepsModifyPackagingExplanation
              }
              <br />
              <Checkbox
                checked={
                  frontmatter.packagingNotesForDesignChangesAndNextStepsUpdateGraphicsCheckbox ===
                  'true'
                }
              />
              &nbsp;Update Graphics:{' '}
              {
                frontmatter.packagingNotesForDesignChangesAndNextStepsUpdateGraphicsExplanation
              }
              <br />
              <Checkbox
                checked={
                  frontmatter.packagingNotesForDesignChangesAndNextStepsOtherCheckbox ===
                  'true'
                }
              />
              &nbsp;Other:{' '}
              {
                frontmatter.packagingNotesForDesignChangesAndNextStepsOtherExplanation
              }
            </p>
          </li>
        </ol>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query MarkdownQueryFormativeEvaluation($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        productName
        dateOfEvaluation
        productDesignTable {
          partNumber
          partDescription
          partRevision
        }
        aesthetic
        material
        components
        manufacturingAndFinish
        color
        other
        designOfTheProductConclusion
        designOfTheProductComponentApprovedCheckbox
        designOfTheProductModifyComponentCheckbox
        designOfTheProductModifyComponentExplanation
        designOfTheProductOtherCheckbox
        designOfTheProductOtherExplanation
        verificationDateOfTest
        verificationProcedure
        verificationTable {
          partNumber
          partDescription
          partRevision
        }
        verificationTestTable {
          testNumber
          testDescription
          quantitativePassOrFailResults
          notes
        }
        verificationConclusion
        verificationNotes
        verificationComponentApprovedCheckbox
        verificationModifyComponentCheckbox
        verificationModifyComponentExplanation
        verificationUpdateRequirementCheckbox
        verificationUpdateRequirementExplanation
        verificationOtherCheckbox
        verificationOtherExplanation
        validationDateOfTest
        validationProcedure
        validationTable {
          partNumber
          partDescription
          partRevision
        }
        validationTestTable {
          resultNumberX
          resultDescription
          resultsPassOrFail
          notes
        }
        validationConclusion
        validationNotes
        validationComponentApprovedCheckbox
        validationModifyComponentCheckbox
        validationModifyComponentExplanation
        validationUpdateTestProcedureCheckbox
        validationUpdateTestProcedureExplanation
        validationOtherCheckbox
        validationOtherExplanation
        manufacturingProcessesDateOfEvaluationOrTest
        manufacturingProcessesTable {
          partNumber
          partDescription
          partRevision
          vendorOrDocument
          manufacturingProcessType
          notes
        }
        manufacturingProcessesConfusingProcedureCheckbox
        manufacturingProcessesConfusingProcedureExplanation
        manufacturingProcessesTimeConsumingCheckbox
        manufacturingProcessesTimeConsumingExplanation
        manufacturingProcessesDifficultTaskCheckbox
        manufacturingProcessesDifficultTaskExplanation
        manufacturingProcessesDesignChangeNotes
        manufacturingProcessesComponentApprovedCheckbox
        manufacturingProcessesModifyComponentCheckbox
        manufacturingProcessesModifyComponentExplanation
        manufacturingProcessesUpdateProcedureOrProcessCheckbox
        manufacturingProcessesUpdateProcedureOrProcessExplanation
        manufacturingProcessesCreateAFixtureCheckbox
        manufacturingProcessesCreateAFixtureExplanation
        manufacturingProcessesOtherCheckbox
        manufacturingProcessesOtherExplanation
        userInteractionTable {
          partNumber
          partDescription
          partRevision
        }
        userInteractionRequiredMaterials
        userInteractionUsabilityScenarioOneDescription
        userInteractionUsabilityScenarioOneResultsOneDateOfEvaluation
        userInteractionUsabilityScenarioOneResultsOneUsabilityProcedure
        userInteractionUsabilityScenarioOneResultsOneParticipantsTitle
        userInteractionUsabilityScenarioOneResultsOneLocationOfEvaluation
        userInteractionUsabilityScenarioOneResultsOneRoomEnvironmentOfEvaluation
        userInteractionUsabilityScenarioOneResultsOneSectionOne
        userInteractionUsabilityScenarioOneResultsOneSectionOneCheckboxYes
        userInteractionUsabilityScenarioOneResultsOneSectionOneCheckboxNo
        userInteractionUsabilityScenarioOneResultsOneSectionTwo
        userInteractionUsabilityScenarioOneResultsOneSectionTwoCheckboxYes
        userInteractionUsabilityScenarioOneResultsOneSectionTwoCheckboxNo
        userInteractionUsabilityScenarioOneResultsOneParticipantNotes
        userInteractionUsabilityScenarioOneResultsTwoDateOfEvaluation
        userInteractionUsabilityScenarioOneResultsTwoUsabilityProcedure
        userInteractionUsabilityScenarioOneResultsTwoParticipantsTitle
        userInteractionUsabilityScenarioOneResultsTwoLocationOfEvaluation
        userInteractionUsabilityScenarioOneResultsTwoRoomEnvironmentOfEvaluation
        userInteractionUsabilityScenarioOneResultsTwoSectionOne
        userInteractionUsabilityScenarioOneResultsTwoSectionOneCheckboxYes
        userInteractionUsabilityScenarioOneResultsTwoSectionOneCheckboxNo
        userInteractionUsabilityScenarioOneResultsTwoSectionTwo
        userInteractionUsabilityScenarioOneResultsTwoSectionTwoCheckboxYes
        userInteractionUsabilityScenarioOneResultsTwoSectionTwoCheckboxNo
        userInteractionUsabilityScenarioOneResultsTwoParticipantNotes
        userInteractionUsabilityScenarioTwoDescription
        userInteractionUsabilityScenarioTwoResultsOneDateOfEvaluation
        userInteractionUsabilityScenarioTwoResultsOneUsabilityProcedure
        userInteractionUsabilityScenarioTwoResultsOneParticipantsTitle
        userInteractionUsabilityScenarioTwoResultsOneLocationOfEvaluation
        userInteractionUsabilityScenarioTwoResultsOneRoomEnvironmentOfEvaluation
        userInteractionUsabilityScenarioTwoResultsOneSectionOne
        userInteractionUsabilityScenarioTwoResultsOneSectionOneCheckboxYes
        userInteractionUsabilityScenarioTwoResultsOneSectionOneCheckboxNo
        userInteractionUsabilityScenarioTwoResultsOneSectionTwo
        userInteractionUsabilityScenarioTwoResultsOneSectionTwoCheckboxYes
        userInteractionUsabilityScenarioTwoResultsOneSectionTwoCheckboxNo
        userInteractionUsabilityScenarioTwoResultsOneParticipantNotes
        userInteractionUsabilityScenarioTwoResultsOneComponentApprovedCheckbox
        userInteractionUsabilityScenarioTwoResultsOneModifyComponentCheckbox
        userInteractionUsabilityScenarioTwoResultsOneModifyComponentExplanation
        userInteractionUsabilityScenarioTwoResultsOneIFUApprovedCheckbox
        userInteractionUsabilityScenarioTwoResultsOneModifyContentCheckbox
        userInteractionUsabilityScenarioTwoResultsOneModifyContentExplanation
        packagingDateOfEvaluationOrTest
        packagingTable {
          partNumber
          partDescription
          partRevision
        }
        packagingTestTable {
          testDescription
          resultsQuantitativeOrPassFail
          notes
        }
        packagingPackagingRelatedInformationCheckbox
        packagingPackagingRelatedInformationExplanation
        packagingPackagingRelatedOrientationCheckbox
        packagingPackagingRelatedOrientationExplanation
        packagingPackagingRelatedUserStorageCheckbox
        packagingPackagingRelatedUserStorageExplanation
        packagingProductRelatedVisualCheckbox
        packagingProductRelatedVisualExplanation
        packagingProductRelatedOrientationCheckbox
        packagingProductRelatedOrientationExplanation
        packagingNotesForDesignChangesAndNextStepsComponentApprovedCheckbox
        packagingNotesForDesignChangesAndNextStepsModifyPackagingCheckbox
        packagingNotesForDesignChangesAndNextStepsModifyPackagingExplanation
        packagingNotesForDesignChangesAndNextStepsUpdateGraphicsCheckbox
        packagingNotesForDesignChangesAndNextStepsUpdateGraphicsExplanation
        packagingNotesForDesignChangesAndNextStepsOtherCheckbox
        packagingNotesForDesignChangesAndNextStepsOtherExplanation
      }
    }
  }
`
