import React from 'react'

const Checkbox = ({ checked = false }) => {
  return checked ? (
    <input type="checkbox" disabled="disabled" checked />
  ) : (
    <input type="checkbox" disabled="disabled" />
  )
}

export default Checkbox
