import React from 'react'
import Image from '../components/Image'
import { Link } from 'gatsby'
import { capitalCase } from 'change-case'
import '../components/all.sass'

/**
 *  If isNumbered is set TRUE, first column of table will be treated as a row
 *  number column and numbers will be implicitly added based on row index in
 *  the map function. The row number column keys still need to be present in
 *  the markdown, but the values for those keys may be left blank.
 *
 * @param table the table data to be rendered
 * @param headers displays provided header rather than header names from MD data
 * @param isNumbered if true, first column will be populated with row numbers
 * @returns {JSX.Element} table component populated with MD data
 */
const Table = function ({ table, headers, isNumbered }) {
  const regex = /\.png/
  return (
    <table className="table is-striped is-narrow is-hoverable">
      {headers ? (
        <tr>
          {headers.map((attr) => (
            <th key="{item}">{attr}</th>
          ))}
        </tr>
      ) : (
        <tr>
          {Object.keys(table[0]).map((attr) => (
            <th key="{item}">{capitalCase(attr)}</th>
          ))}
        </tr>
      )}
      {table.map((row, rowIndex) => (
        <tr>
          {Object.values(row).map((value, colIndex) => {
            if (isNumbered && colIndex === 0) {
              return <td>{rowIndex + 1}</td>
            } else if (Object.keys(table[0])[colIndex] === 'documentLink') {
              return (
                <td>
                  <Link to={value} replace>
                    {capitalCase(value)}
                  </Link>
                </td>
              )
            } else if (value.match(regex)) {
              return (
                <td>
                  <Image filepath={value} />
                </td>
              )
            } else {
              return <td>{value}</td>
            }
          })}
        </tr>
      ))}
    </table>
  )
}

export default Table
